body {
	font-family: Inter, Noto Sans Bengali, sans-serif;
	overflow-x: hidden;
	background-color: #f3f4f6;
}

html {
	scroll-behavior: smooth;
}

.dropdown:hover .dropdown-menu {
	display: block;
}

[role="button"] {
	cursor: default !important;
}

.hover-underline-animation {
	display: inline-block;
	position: relative;
}

.react-confirm-alert-button-group button {
	background-color: #0087ca !important;
	width: 100%;
	height: 40px;
	font-size: 16px !important;
}

.hover-underline-animation:after {
	content: "";
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: #0087ca;
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
